<template>
  <div
    v-if="langTxt"
    class="bs-c-swich-wrap"
  >
    <div
      v-tap="{ id: 'click_change_currency.comp_category' }"
      class="bs-c-swich-wrap__item"
      @click="openDrawer('currency')"
    >
      <div class="bs-c-swich-wrap__left">
        <div class="bs-c-swich-wrap__left-icon">
          <Currency />
        </div>
        <span class="bs-c-swich-wrap__left-name">{{ langTxt.currency }}</span>
      </div>
      <span class="bs-c-swich-wrap__right">{{ default_currency }}</span>
    </div>
    <div
      v-if="isShowLanguag"
      v-tap="{ id: 'click_change_language.comp_category' }"
      class="bs-c-swich-wrap__item bs-c-swich-wrap__item-lang"
      @click="openDrawer('language')"
    >
      <div class="bs-c-swich-wrap__left">
        <div class="bs-c-swich-wrap__left-icon">
          <Language />
        </div>
        <span class="bs-c-swich-wrap__left-name">{{ langTxt.language }}</span>
      </div>
      <span class="bs-c-swich-wrap__right">{{ default_language }}</span>
    </div>
  </div>
</template>

<script name="BSwichLangCurrency" setup lang="ts">
import {ref, onBeforeMount, provide} from 'vue'
import Language from './icons/Language.vue'
import Currency from './icons/Currency.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { C_SwichLangCurrency, AS_Category } from '../../../../types'
const appConfigs = useAppConfigs()
import { getSource } from '../../../../common/dataSource'
const apis = getSource({ appConfigs })
const default_language = ref<string | ''>()
const default_currency = ref<string | ''>()
const language_list = ref<C_SwichLangCurrency.LANG_LIST | []>()
const currency_infos = ref<C_SwichLangCurrency.CURRENCY_LIST | []>()
const isShowLanguag = ref(false)
import { useAnalysis as useSdkAnalysis } from '../../../../common/analysisSource'

const props = defineProps<{
  langTxt?: C_SwichLangCurrency.LANG_TXT,
  analysisSource?: AS_Category.AnalysisSource
}>()

const analysisInstance = useSdkAnalysis(props.analysisSource)
const { vTap } = analysisInstance

provide('analysisInstance', analysisInstance)

onBeforeMount(async () => {
  // 获取语种列表
  const [langInfo, currencyInfo] = await Promise.all([
    apis.getlangs(),
    apis.getCurrencys(),
  ])
  language_list.value = langInfo?.info?.language_list || []
  isShowLanguag.value = langInfo?.info?.language_list?.length > 0
  currency_infos.value = currencyInfo?.info?.currency_infos || []
  default_language.value = langInfo?.info?.language_list?.find?.(_ => _.web_lang == appConfigs.$envs.lang)?.title || ''
  default_currency.value = appConfigs.$envs.currency
})

const emit = defineEmits(['open-drawer'])

const openDrawer = type => emit('open-drawer', {
  type: type,
  data: type == 'language' ? language_list.value : currency_infos.value
})
</script>

<style lang="less" scoped>
@vw: 375 / 100vw;
.bs-c-swich-wrap {
  margin-top: 14 / @vw;
  margin-top: min(14 / @vw, 20px);
  background-color: #fff;
  &__item {
    height: 44 / @vw;
    line-height: 44 / @vw;
    font-size: 14 / @vw;
    margin-left: 20 / @vw;

    height: min(44 / @vw,64px);
    line-height: min(44 / @vw,64px);
    font-size: min(14 / @vw,20px);
    background-color: #fff;
    margin-left: min(20 / @vw, 28px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
  }
  &__item-lang {
    border-top: 1px solid #e5e5e5;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__left-icon {
    margin-right: 12 / @vw;
    font-size: 24 / @vw;

    margin-right: min(12 / @vw, 17.28px);
    font-size: min(24 / @vw, 34.5px);
    display: flex;
    align-items: center;
  }
  &__right {
    font-size: 12 / @vw;
    margin-right: 12 / @vw;

    color: #999;
    font-size: min(12 / @vw, 17.28px);
    margin-right: min(12 / @vw, 17.28px);
  }
}
</style>
