<template>
  <ul
    class="c-skeleton__sidecat-left"
  >
    <li
      v-for="n in 20"
      :key="n"
      class="c-skeleton__sidecat-left-item"
      :class="cateType"
    >
      <div v-if="cateType === 'sidecat'" class="sidecat-left__img"></div>
      <div class="sidecat-left__name"></div>
      <Icon
        v-if="cateType === 'sidecat'"
          class="bs-left-item_arr"
          :name="cssRight ? 'sui_icon_more_left_16px':'sui_icon_more_right_16px'"
          size="16px"
          color="#999"
        />
    </li>
  </ul>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import {defineProps} from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

defineProps({
  cateType: {
    type: String,
    default: 'sidecat'
  }
})
const { cssRight = false } = useAppConfigs().$envs || {}

</script>

<style lang="less">
.c-skeleton {
  &__sidecat-left {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background-color: #FFF;
  }
    &__sidecat-left-item {
    display: flex;
    align-items: center;
    height: 16vw;
    height: min(16vw,86.4px);
    margin-left: 0;
    padding-left: 20px;
    padding-right: 12px;
    text-align: left;
    &.cat{
      padding: 0;
      height: auto;
      margin: 11.25px 8px;
      .sidecat-left__name{
        height: 30.5px;
        margin: 0;
      }
    }
  }
}

.sidecat-left {
  &__img {
    width: 11.733vw;
    height: 11.733vw;
    width: min(11.733vw,64px);
    height: min(11.733vw,64px);
    background: #f5f5f5;
    border-radius: 100%;
  }
  &__name {
    flex: 1;
    width: 30vw;
    height: 3.733vw;
    width: min(30vw,162px);
    height: min(3.733vw,22px);
    margin: 0 24px 0 12px;
    background: #f5f5f5;
  }
}
</style>
