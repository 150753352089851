<template>
  <div>
    <SkeletonHeader v-if="loading" />
    <STab
      v-else
      ref="tabRef"
      v-model="selectTab"
      type="auto"
      @change="handleTabChange">
      <STabItem
        v-for="(item, index) in tabData"
        :id="item.id"
        :key="item.id"
        v-expose="{ id: 'expose_top_category.comp_category', code: 'top_category', data: item.saInfo, delayReady: 1500 }"
        v-tap="{ id: 'click_top_category.comp_category', data: item.saInfo }"
        :aria-label="item.name"
        :tabindex="index"
        role="button"
      >
        {{ item.name }}
      </STabItem>
    </STab>
  </div>
</template>
<script name="TabHeader" setup>
import { ref, defineProps, defineEmits, watch, defineExpose, inject } from 'vue'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import SkeletonHeader from '../skeleton/header.vue'

const { vTap, vExpose } = inject('analysisInstance')

const props = defineProps({
  activeKey: {
    type: String || Number,
    default: '',
  },
  tabData: {
    type: Array,
    default: () => [],
  },
  getUserSaAbt: {
    type: Function,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: true,
  },
})

// const { getTopNavSa } = useAnalysis(props.getUserSaAbt)

const emits = defineEmits(['change'])

const selectTab = ref(props.activeKey)

watch(
  () => props.activeKey,
  val => {
    selectTab.value = val
  },
  { immediate: true },
)

const handleTabChange = data => {
  console.log(data)
  if (!data.oldVal) return // 首次进入页面不触发
  let item, itemIndex
  props.tabData.forEach((i, index) => {
    if (i.id == data.val) {
      item = i
      itemIndex = index
    }
  })
  emits('change', { channel: item, index: itemIndex })

  // emits('change', { item, itemIndex, target: document.querySelector(`.j-c-content__tab-item-${data.val}`) })
}

const tabRef = ref(null)
function scrollToSelected() {
  if (tabRef.value) {
    tabRef.value.scrollToSelected()
  }
}

defineExpose({ scrollToSelected })
</script>
