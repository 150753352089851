<template>
  <span
    style="vertical-align: middle; display: inline-block; line-height: 0"
    :style="{ fontSize: `${size}px` }"
    @click="emits('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-for="(d,i) in paths"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="d"
        fill="var(--shein-common-header-icon-color, #000)"
      ></path>
    </svg>
  </span>
</template>
<script setup>
import {defineProps,defineEmits} from 'vue'

defineProps({
  size: {
    type: Number,
    default: 24,
  },
  textColor: {
    type: String,
    default: '#1C1C1C',
  },
})
const emits = defineEmits(['click'])
const paths = ["M21 4.5V6H3V4.5H21Z","M21 11.25V12.75H3V11.25H21Z","M21 19.5V18H3V19.5H21Z"]
</script>
