<template>
  <div class="base-img" :data-img-src="baseCutImg" :data-design-width="imgDesignWidth">
    <a
      href="javascript:;"
      :style="{ paddingBottom: customRatio || getImgPlaceholder() }"
      class="base-img__href"
      role="link"
      tabindex="0"
      :aria-label="ada"
      @click="emits('click')"
    >
      <img
        v-if="firstScreen || showImmediately"
        :class="[
          'base-img__inner',
          `base-img__${fit}`,
          'fsp-element',
          compSrc ? `fsp__${compSrc}` : '',
        ]"
        :src="baseCutImg"
        :style="getImmediateImageStyle()"
        :alt="alt"
        fetchpriority="high"
      />
      <img
        v-else
        :class="['base-img__inner lazyload', `base-img__${fit}`, compSrc ? `fsp__${compSrc}` : '']"
        :style="{
          objectPosition: props.position,
          ...raidusStyle,
        }"
        :data-src="imgSrc"
        :src="emptyImg || EmptyNewImg"
        :data-design-width="imgDesignWidth"
        :data-exp="imgDataExpStr"
        :alt="alt"
        @loaded="emits('baseImageMounted')"
      />
    </a>
  </div>
</template>
<script setup name="baseImg">
// import {  Cut, imgExpandData2Str } from '@shein-aidc/basis-resource'
import { defineProps, defineEmits, computed } from 'vue'

const EmptyNewImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89B8AAukB8/71MdcAAAAASUVORK5CYII='
const props = defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  // 裁剪方式
  fit: {
    type: String, // 'none' | 'contain' | 'cover' | 'fill' | 'scale-down' | 'initial' | 'inherit'
    default: 'initial',
  },
  // 裁剪定位
  position: {
    type: String, // 同 object-position
    default: 'initial',
  },
  // 占位图使用方式参考：
  // wiki.pageId=611059170
  placeholder: {
    type: Object,
    default: () => ({
      width: 500,
      height: 500,
      query: '',
    }),
  },
  // 高/宽比率
  ratio: {
    type: [Number, String],
    default: 0,
  },
  ada: {
    type: String,
    default: 'Photo',
  },
  alt: {
    type: String,
    default: '',
  },
  imgDesignWidth: {
    type: [Number, String],
    default: 0,
  },
  imgDataExp: {
    type: Object,
    default: () => ({}),
  },
  firstScreen: {
    // 精细控制
    type: Boolean,
    default: false,
  },
  customRatio: {
    // 自定义图片的宽高比例
    type: String,
    default: '',
  },
  emptyImg: {
    type: String,
    default: '',
  },
  // 直接作用在 img 标签上的圆角
  specialRadius: {
    type: String,
    default: '',
  },
  compSrc: {
    type: String,
    default: '',
  },
  showImmediately: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['baseImageMounted', 'click'])
const imgDataExpStr = '' // imgExpandData2Str(props.imgDataExp)
const raidusStyle = props.specialRadius ? { borderRadius: props.specialRadius } : {}

const getImmediateImageStyle = () => {
  return props.showImmediately
    ? {
        width: '100%',
        height: '100%',
        'vertical-align': 'top',
        position: 'absolute',
        left: 0,
        objectPosition: props.position,
        ...raidusStyle,
      }
    : {
        objectPosition: props.position,
        ...raidusStyle,
      }
}

const baseCutImg = computed(() => {
  return props.imgSrc.replace(/^https?\:/, '')
})
const getBaseCutImg = () => {
  return props.imgSrc.replace(/^https?\:/, '')
  // if (!injections?.cutImg) {
  // }

  // return injections.cutImg(
  //   props.imgSrc,
  //   props.imgDesignWidth,
  //   props.imgDataExp
  // )
}

const getImgPlaceholder = () => {
  if (props.ratio) {
    return (100 / props.ratio).toFixed(2) + '%'
  } else {
    const { height, width } = props.placeholder
    return ((height / width) * 100).toFixed(2) + '%'
  }
}
</script>

<style lang="less">
.base-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  // margin-bottom: -1px; // 1px以防露出间隙

  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
    left: 0;
  }
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
  &__scale-down {
    object-fit: scale-down;
  }
  &__initial {
    object-fit: initial;
  }
  &__inherit {
    object-fit: inherit;
  }
  img[src=''],
  img:not([src]) {
    // 解决图片加载失败出来边框的问题
    opacity: 0;
  }
}
</style>
