<template>
  <svg
    style="width: auto; height: 1em"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.00391C6.48003 2.00391 2.00403 6.47991 2.00403 11.9999C2.00403 17.5199 6.48003 21.9959 12 21.9959C17.52 21.9959 21.996 17.5199 21.996 11.9999C21.996 6.47991 17.52 2.00391 12 2.00391ZM12 20.4959C7.30803 20.4959 3.50403 16.6919 3.50403 11.9999C3.50403 7.30791 7.30803 3.50391 12 3.50391C16.692 3.50391 20.496 7.30791 20.496 11.9999C20.496 16.6919 16.692 20.4959 12 20.4959Z"
      fill="#1C1C1C"
    />
    <path
      d="M12.504 11.3041V8.26809C13.164 8.34009 13.716 8.65209 14.028 9.07209L15.252 8.19609C14.664 7.36809 13.644 6.84009 12.516 6.75609V5.49609H11.004V6.91209C9.63598 7.32009 8.62798 8.41209 8.62798 9.74409C8.62798 11.0521 9.43198 11.8081 11.004 12.3961V15.5041C10.452 15.3121 9.89998 15.0241 9.46798 14.6881L8.53198 15.8641C9.21598 16.4041 10.128 16.8481 11.004 17.0761V18.4921H12.504V17.2321C14.304 17.1001 15.756 15.8521 15.756 14.2441C15.744 12.7201 14.568 11.8801 12.504 11.3041ZM11.004 10.7761C10.392 10.4761 10.128 10.1521 10.128 9.75609C10.128 9.27609 10.464 8.80809 11.004 8.53209V10.7761ZM12.504 15.7321V12.8761C13.692 13.2601 14.256 13.7041 14.256 14.2561C14.256 14.9641 13.512 15.6241 12.504 15.7321Z"
      fill="#1C1C1C"
    />
  </svg>
</template>
