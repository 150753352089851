<template>
  <footer
    class="sidebar-cate__footer"
    @touchmove.stop.prevent
  >
    <a
      v-tap="getSaInfo('1\`home')"
      :href="`${langPath}`"
      :aria-label="language.SHEIN_KEY_PWA_14979"
      data-action="Home"
    >
      <Icon
        name="sui_icon_nav_shop_24px"
        size="24px" />
    </a>
    <a
      v-tap="getSaInfo('2\`wishlist')"
      href="javascript:void(0);"
      :aria-label="language.SHEIN_KEY_PWA_15497"
      data-action="ClickWishlist"
      @click="goWishList(`${langPath}/user/wishlist`)"
    >
      <Icon
        name="sui_icon_nav_save_24px"
        size="24px" />
    </a>
    <a
      v-show="showMerchants"
      href="javascript:void(0);"
      aria-label="merchant"
      @click="gotoRobot('merchant')"
    >
      <span class="support-icon">
        <Icon
          name="sui_icon_comment_24px"
          size="24px" />
        <!-- <em
          v-if="unReadChatMerchantNum > 0"
          :class="['badge-num',cssRight ? 'left' : 'right']">
          {{ unReadChatMerchantNum > 99 ? '99+' : unReadChatMerchantNum }}
        </em> -->
        <span
          v-if="unReadChatMerchantNum > 0"
          class="support-icon__un-read"
          :style="cssRight ? {left:'-1px'}: {right:'-1px'}"></span>
      </span>
    </a>
    <a
      v-show="showService"
      v-tap="getSaInfo('3\`customer_service')"
      href="javascript:void(0);"
      data-action="ClickCustomerService"
      aria-label="service"
      @click="gotoRobot('service')"
    >
      <span class="support-icon">
        <Icon
          name="sui_icon_pay_support_24px"
          size="24px" />
        <span
          v-if="unReadChatNum > 0"
          class="support-icon__un-read"
          :style="cssRight ? {left:'-3px'}: {right:'-3px'}"></span>
      </span>
    </a>
    <a
      v-tap="getSaInfo('4\`me')"
      href="javascript:void(0);"
      :aria-label="language.SHEIN_KEY_PWA_16036"
      data-action="ClickMe"
      @click="gotoRouter(`${langPath}/user/index`)"
    >
      <Icon
        name="sui_icon_nav_me_24px"
        size="24px" />
    </a>
  </footer>
</template>
<script setup name="BSlideCategoryFooter">
import { defineEmits, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { stringifyQueryString } from '@shein/common-function'

const { vTap } = inject('analysisInstance')

const props = defineProps({
  language: {
    type:Object,
    default:() => ({}),
  },
  customJump:{
    type: Boolean,
    default: true,
  },
  unReadChatNum: {
    type: Number,
    default: 0,
  },
  unReadChatMerchantNum: {
    type: Number,
    default: 0,
  },
  showService:{
    type: Boolean,
    default: true,
  },
  showMerchants: {
    type: Boolean,
    default: true,
  },
})
const emits = defineEmits(['goPage', 'closeDrawer'])
const { langPath, cssRight = false } = useAppConfigs().$envs || {}

function getSaInfo(account_content){
  return {
    id:'click_top_site_account.comp_category',
    data: { account_content },
  }
}
function gotoRobot(type){
  if(props.customJump){
    emits('goPage','gotoRobot', type)
  }else{
    emits('closeDrawer')
    location.href = `${langPath}/robot?page=&page_name=page_home`
  }
}

function gotoRouter(path){
  if(props.customJump){
    emits('goPage', path)
  }else{
    emits('closeDrawer')
    location.href = path
  }
}

function goWishList(path){
  if(props.customJump){
    emits('goPage', path)
    return
  }
  const { memberId } = useAppConfigs().$getters || {}
  const expirationTime = Number(localStorage.getItem(`wish_shein_club_key_${memberId}`))
  let cpm = expirationTime && Date.now() < expirationTime ? '1' : ''
  // if (expirationTime && Date.now() < expirationTime) {
  //   cpm = '1' // 还没有过期， 添加标识
  // }
  const queryObj = { pf: 'sidetab', cpm }
  emits('closeDrawer')
  location.href = `${location.origin}${langPath}/wishlist?${stringifyQueryString({queryObj})}`
}
</script>

<style lang="less">
.sidebar-cate {
  &__footer {
    display: flex;
    flex-flow: row wrap;
    height: 13.86vw;
    height: min(13.86vw,75px);
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #fff;
    border-top: 1px solid #E5E5E5;
    > a {
      flex: 1;
      height: 11.733vw;
      height: min(11.733vw,64px);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #222;
    }
    .support-icon{
      position: relative;
      &__un-read {
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #FE3B30;
        border-radius: 50%;
        top: -6px;
      }
    }
  }
}
</style>
