<template>
  <div>
    <div
      class="sidebar-cate__app-ctn"
      aria-hidden="true"
      role="none"
      tabindex="-1"
    >
      <div class="sidebar-cate__app-title">
        {{ language.SHEIN_KEY_PWA_16210 }}
      </div>
      <div
        class="sidebar-cate__img-download"
        da-event-click="1-14-1-6"
        :style="'background-image:url(' + downloadImg + ')'"
        @click="openApp"
      ></div>
    </div>
    <!-- <div
      v-if="addScreenIcon"
      class="sidebar-cate__add-screen"
      da-event-click="1-14-1-7"
      aria-hidden="true"
      role="none"
      tabindex="-1"
      @click="clickAddScreen"
    >
    <span>
      {{ language.SHEIN_KEY_PWA_14981 }}
    </span>
    <Icon name="sui_icon_add_screen_24px" size="24px" />
    </div> -->
  </div>
</template>
<script setup>
import { ref, defineProps, onMounted } from 'vue'
// import { Icon } from '@shein-aidc/icon-vue3'
import { isSupportSW } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  rootStatus: {
    type: Object,
    default: () => ({})
  },
})
const downloadImg = ref('')
const isAppClicked = ref(false)
const addScreenIcon = ref(false)

const sheinAppUrlIos = {
  'mru': 'https://apps.apple.com/ru/app/shein-fashion-shopping-online/id878577184',
  'pwde': 'https://apps.apple.com/de/app/shein-shopping-und-fashion/id878577184',
  'pwit': 'https://apps.apple.com/it/app/shein-moda-e-shopping/id878577184',
  'pwfr': 'https://apps.apple.com/fr/app/shein-vente-privee-mode-femme/id878577184',
  'mth': 'https://apps.apple.com/th/app/shein-fashion-shopping-online/id878577184',
  'mtw': 'https://apps.apple.com/tw/app/shein-fashion-shopping-online/id878577184',
  'mhk': 'https://apps.apple.com/hk/app/shein-fashion-shopping-online/id878577184?l=en',
  'pwau': 'https://apps.apple.com/au/app/shein-fashion-shopping-online/id878577184',
  'pwuk': 'https://apps.apple.com/gb/app/shein-fashion-shopping-online/id878577184',
  'pwmx': 'https://apps.apple.com/mx/app/shein-fashion-online-shopping/id878577184',
  'default': 'https://itunes.apple.com/cn/app/shein-fashion-shopping/id878577184?mt=8'
}

const iosImg = 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/50/17229962340f4066d2cc34aaf238d578ad2a08e3a4.webp'
const androidImg = 'https://img.ltwebstatic.com/images3_ccc/2024/08/07/be/1722996326d6b4ecca77f6b28c8bc97a4384fd74c1.webp'
onMounted(() => {
  downloadImg.value = navigator.userAgent.match(/(iPhone|iPod|iPad);?/i) ? iosImg : androidImg
  // handleWishlistShow()
})

const openApp = () => {
  if (isAppClicked.value) return
  const { siteUID } =  useAppConfigs().$envs || {}
  isAppClicked.value = true
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    window.location.href = sheinAppUrlIos[siteUID] || sheinAppUrlIos['default']
  } else if (navigator.userAgent.match(/android/i)) {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.zzkko'
  }
  setTimeout(() => {
    isAppClicked.value = false
  }, 500)
}
const handleWishlistShow = () => {
  if (isSupportSW()) {
    addScreenIcon.value = true
  }
}
const clickAddScreen = () => {
  console.log('close slide cate');
  // $('.j-h-screen-ico').click()
  // changeRootStatus({ show_category: false })
}
</script>

<style lang="less">
.sidebar-cate {
  &__app-ctn {
    position: relative;
    padding: 20px !important;
    width: 100%;
    background: #F6F6F6;
  }

  &__app-title {
    display: block;
    font-weight: bold;
    font-size: 3.733vw !important;
    font-size: min(3.733vw, 24px) !important;
  }

  &__img-download {
    width: 29.2vw;
    height: 8.5vw;
    margin-top: 10px;
    width: min(29.2vw,158px);
    height: min(8.5vw,46px);
    background-size: 100% 100%;
  }

  // &__add-screen {
  //   width: 7.14rem;
  //   margin: 0 auto;
  //   padding: .53rem 0;
  //   border-top: 1px solid #e5e5e5;
  //   font-size: 28px;
  //   line-height: 1.2;
  //   font-weight: bold;

  //   span {
  //     display: inline-block;
  //     position: relative;
  //   }

  //   [class*="iconfont"] {
  //     position: absolute;
  //     font-size: 48px;
  //     top: 50%;
  //     transform: translate(.48rem, -50%);
  //     font-weight: normal;
  //   }
  // }
}
</style>
