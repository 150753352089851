<template>
  <svg
    style="width: auto; height: 1em;"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2426 4.2876L13.3033 5.34826L6.40897 12.2425L2.69666 8.53024L3.75732 7.46958L6.40896 10.1214L12.2426 4.2876Z"
      fill="#1C1C1C"
    />
  </svg>
</template>
