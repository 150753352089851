<template>
  <div class="bs-cate__entire-wrap" :class="{ expand: true }">
    <div
      v-expose="{
        id: 'expose_second_category.comp_category',
        data: {
          item: cate.props,
          index: compIndex,
          topNavSa: activeChannel?.channel?.saInfo,
          oneCateContent: activeLeftContent?.cate?.saInfo,
          cateType,
        },
        code: cateType,
        delayReady: 300,
      }"
      v-tap="{
        id: 'click_second_category.comp_category',
        data: {
          item: cate.props,
          index: compIndex,
          topNavSa: activeChannel?.channel?.saInfo,
          oneCateContent: activeLeftContent?.cate?.saInfo,
          cateType,
        },
      }"
      class="c-cate__entire-title"
      :class="{ 'c-cate__entire-title--first-line': cateItem?.categoryType?.position == 0 }"
      @click.stop="clickItem"
    >
      <a aria-hidden="true" :style="{ color: cate.color }" role="none" tabindex="-1" @click="aTagpreventDefault">
        <div class="c-cate__link-font">
          {{ cate?.props?.metaData?.categoryLanguage }}
        </div>
        <div class="c-cate__link-icon">
          <Icon
            v-if="icon == 1 || icon == -1"
            class="bs-left-item_arr"
            name="sui_icon_more_right_16px"
            :is-rotate="cssRight"
            size="12px"
            color="#999"
            :style="{ visibility: icon != 1 && 'hidden' }"
          />
        </div>
      </a>
    </div>
    <CateClassify
      v-if="cate.child"
      :class="{ 'animate-ready': true && !noExpand, 'no-animate': noExpand }"
      :cate="cate?.child[0]?.props?.items"
      :cate-props="cate"
      :cate-type="cateType"
      :activeChannel="activeChannel"
      :activeLeftContent="activeLeftContent"
      :compIndex="compIndex"
      :is-active="isActive"
      :is-child-cate="isChildCate"
      :abtMap="abtMap"
      @jumpLink="(url, options) => emits('jumpLink', url, options)"
      @clickItem="clickItem"
    />
  </div>
</template>

<script setup>
import CateClassify from './CateClassify.vue'
import { defineProps, defineEmits, computed, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const { vTap, vExpose } = inject('analysisInstance')

const props = defineProps({
  cate: {
    type: Object,
    default: () => ({}),
  },
  cateType: {
    type: String,
    default: '',
  },
  activeChannel: {
    type: Object,
    default: () => ({}),
  },
  activeLeftContent: {
    type: Object,
    default: () => ({}),
  },
  compIndex: {
    type: Number,
    default: 0,
  },
  cateLinks: {
    type: Object,
    default: () => ({}),
  },
  pos: {
    type: String,
    default: '',
  },
  noExpand: {
    type: Boolean,
    default: false,
  },
  isChildCate: {
    type: Boolean,
    default: false,
  },
  handleClose: {
    type: Function,
    default: () => {},
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  abtMap: {
    type: Object,
    default: () => ({}),
  }
})
const emits = defineEmits(['clickItem', 'jumpLink'])
const { cssRight = false } = useAppConfigs().$envs || {}
const cateItem = computed(() => props?.cate?.props?.metaData)
const icon = computed(() => {
  if (props?.cateType === 'sidecat') {
    return -1
  }
  if (props?.cate.props?.metaData?.categoryType?.hrefType !== 'noJump') {
    return 1
  }
  return 0
})

const clickItem = () => {
  const categoryType = props.cate?.props?.metaData?.categoryType
  const params = {
    cate: props.cate?.props?.metaData,
    cateType: props.cateType,
    channel: props.activeChannel?.channel,
    channelIndex: props.activeChannel?.channelIndex,
    oneCateContent: props.activeLeftContent,
    compIndex: props.compIndex,
    oneCate: props.activeChannel?.oneCate,
  }
  emits(
    'clickItem',
    {
      type: 'title',
      item: categoryType,
      cateLinks: {},
      compIndex: props.compIndex,
      index: -1,
      isJump: categoryType.hrefType != 'noJump',
    },
    params,
  )
}
const aTagpreventDefault = e => {
  e.preventDefault()
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.bs-cate__entire-wrap {
  overflow: hidden;
  min-height: 12.53vw;
  min-height: min(12.53vw, 67.66px);
  .animate-ready {
    transition: 0.3s margin-top cubic-bezier(0.2, 0.63, 0.57, 0.94);
  }
  .c-cate__classify-wrap {
    opacity: 0;
    animation-name: opacity-leave;
    animation-timing-function: ease;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    // animation-play-state: running;
  }
  &.expand {
    .title-banner {
      opacity: 0;
    }
  }
  @keyframes opacity-enter {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes opacity-leave {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  .c-cate {
    &__entire-title {
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      font-weight: normal;
      color: #222;
      display: flex;
      align-items: center;
      min-height: 8.55vw;
      line-height: 3.2vw;
      min-height: min(8.55vw, 46.17px);
      line-height: min(3.2vw, 17.28px);
      justify-content: space-between;
      width: 100%;
      margin-top: 0;
      padding: 0 0 0 0;
      background: #fff;
      a {
        display: flex;
        justify-content: space-between;
        flex: 1;
        overflow: hidden;
        text-decoration: none;
        color: #666;
      }
    }
    &__link-font {
      font-weight: 500;
      font-size: 3.2vw;
      font-size: min(3.2vw, 17.28px);
      color: #222;
      line-height: 8.55vw;
      line-height: min(8.55vw, 46.17px);
      transition: 0.3s all cubic-bezier(0.2, 0.63, 0.57, 0.94);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__link-name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__link-icon {
      display: flex;
      align-items: center;
    }
    &__entire-title--first-line {
      margin-top: 0;
    }
  }
}
</style>
